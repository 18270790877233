import { ref } from 'vue';
import { featurePlan } from '@/data';
import { FeaturePlanData } from '@/components/view/installer/community/read-instructions-dialog/all.type';
import { GetDifferenceData, DifferenceData } from '@/components/view/installer/community/change-plan-dialog/all.type';
import Notice from '@/util/notice.base';
import HttpRequest from '@/util/axios.config';

const initFeaturePlan = (
    initFeatureId: string,
    initCommunityId: string,
    emit: any
) => {
    // disFeaturePlan数据
    const featurePlanData = ref<Array<FeaturePlanData>>([]);

    // 编辑的数据
    const editFeaturePlanData = ref<FeaturePlanData>({
        Items: [],
        Name: '',
        FeaturePlanType: '',
        FeeData: {
            FeatureFee: 0,
            FeatureFeeType: '',
            FeatureName: '',
            FeaturePlanType: '',
            ID: '',
            UUID: ''
        }
    });

    const featurePlanID = ref(initFeatureId);

    const isShowOrderDialog = ref(false);

    const differenceData = ref<DifferenceData>({
        isDifference: 0
    });

    const changeFeaturePlanData = ref<FeaturePlanData>({
        Items: [],
        Name: '',
        FeaturePlanType: '',
        FeeData: {
            FeatureFee: 0,
            FeatureFeeType: '',
            FeatureName: '',
            FeaturePlanType: '',
            ID: '',
            UUID: ''
        }
    });

    const changeFeaturePlan = () => {
        const params: GetDifferenceData = {
            OldFeaturePlanId: editFeaturePlanData.value.ID as string,
            NewFeaturePlanId: featurePlanID.value,
            CommunityId: initCommunityId
        };

        featurePlanData.value.forEach((featurePlanItem: FeaturePlanData) => {
            if (featurePlanItem.ID === featurePlanID.value) {
                changeFeaturePlanData.value = { ...featurePlanItem };
            }
        });

        featurePlan.getDifference(params, ((res: DifferenceData) => {
            // 社区未支付过
            if (res.isDifference === 0 || res.isDifference === 1) {
                // 不补差价
                Notice.messageBox(
                    'confirm',
                    WordList.ProperAllTextChangeFeatureNotice,
                    '',
                    'warning'
                )(() => {
                    // 换方案
                    HttpRequest.post('v3/web/community/featurePlan/setNoDifferenceFeaturePlan', params, () => {
                        emit('close', { featurePlanID: featurePlanID.value });
                    }, () => {
                        featurePlanID.value = editFeaturePlanData.value.ID as string;
                    });
                    if (res.isDifference === 0) {
                        Notice.messageBox(
                            'alert',
                            WordList.ProperAllTextDifferenceSuccessAlert,
                            '',
                            'warning'
                        )(() => {
                            //
                        });
                    }
                }, () => {
                    featurePlanID.value = editFeaturePlanData.value.ID as string;
                });
            } else if (res.isDifference === 2) {
                // 补差价
                Notice.messageBox(
                    'confirm',
                    WordList.ProperAllTextChangeFeaturePay,
                    WordList.Notice,
                    'warning',
                    WordList.OrderPay
                )(() => {
                    differenceData.value = res;
                    isShowOrderDialog.value = true;
                }, () => {
                    featurePlanID.value = editFeaturePlanData.value.ID as string;
                });
            }
        }));
    };

    const orderDialogClose = () => {
        featurePlanID.value = editFeaturePlanData.value.ID as string;
        isShowOrderDialog.value = false;
    };

    featurePlan.getDisFeaturePlan({ Type: '0' }, (res: Array<FeaturePlanData>) => {
        featurePlanData.value = res;
        if (initFeatureId === '0') {
            editFeaturePlanData.value.ID = initFeatureId;
            editFeaturePlanData.value.FeeData.UUID = '0';
            return;
        }
        featurePlanData.value.forEach((featurePlanItem: FeaturePlanData) => {
            if (featurePlanItem.ID === initFeatureId) {
                editFeaturePlanData.value = { ...featurePlanItem };
            }
        });
    });

    return {
        featurePlanData,
        editFeaturePlanData,
        changeFeaturePlan,
        featurePlanID,
        differenceData,
        isShowOrderDialog,
        orderDialogClose,
        changeFeaturePlanData
    };
};
export default initFeaturePlan;