
import { defineComponent, PropType } from 'vue';
import { DifferenceData } from '@/components/view/installer/community/change-plan-dialog/all.type';
import initOrderPay from '@/components/view/installer/community/change-plan-dialog/initOrderPay';
import processing from '@/components/view/common/subscription/module/pay/processing.vue';

export default defineComponent({
    components: {
        processing
    },
    props: {
        initData: {
            type: Object as PropType<DifferenceData>,
            required: true
        },
        oldFeeId: {
            required: true,
            type: String
        },
        newFeeId: {
            required: true,
            type: String
        },
        initCommunityId: {
            required: true,
            type: String
        },
        featureFeeType: {
            required: true,
            type: String
        },
        newFeatureId: {
            required: true,
            type: String
        }
    },
    emits: ['close', 'success'],
    setup(props, { emit }) {
        const {
            agree, pay, isProcessing, payJump
        } = initOrderPay(
            emit,
            props.initData,
            props.oldFeeId,
            props.newFeeId,
            props.initCommunityId,
            props.featureFeeType,
            props.newFeatureId
        );

        return {
            emit,
            agree,
            pay,
            isProcessing,
            payJump
        };
    }
});

