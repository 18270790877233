import { ref } from 'vue';
import { DifferenceData } from '@/components/view/installer/community/change-plan-dialog/all.type';
import HttpRequest from '@/util/axios.config';

const initOrderPay = (
    emit: any,
    differenceData: DifferenceData,
    oldFeeId: string,
    newFeeId: string,
    initCommunityId: string,
    featureFeeType: string,
    newFeatureId: string
) => {
    const copyDifferenceData = ref(differenceData);

    const isProcessing = ref(false);

    const agree = ref(false);

    const payJump = ref();

    const pay = () => {
        HttpRequest.post('v2/createDifferenceOrder', {
            OldFeeUUID: oldFeeId,
            NewFeeUUID: newFeeId,
            CommunityID: initCommunityId,
            FeatureFeeType: featureFeeType,
            NewFeatureID: newFeatureId
        }, [(res: {
                data: {
                    orderID: string;
                    bmurl: string;
                };
            }) => {
            payJump.value = res.data.bmurl;
            window.open(res.data.bmurl);
            isProcessing.value = true;
        }, false]);
    };
    return {
        copyDifferenceData,
        agree,
        pay,
        isProcessing,
        payJump
    };
};
export default initOrderPay;