
import {
    defineComponent, PropType, ref, Ref, computed, reactive, watch
} from 'vue';
import {
    timeZone, community, systemConfig
} from '@/data/';
import { setting as postalCodeRule } from '@/methods/rule';
import HttpRequest from '@/util/axios.config';
import noticeBase from '@/util/notice.base';
import {
    addFeaturePlanData,
    editFeaturePlanData
} from '@/components/view/installer/community/community-set-dialog/initFeaturePlanData';
import ReadInstructionsDialog from '@/components/view/installer/community/read-instructions-dialog';
import ChangePlanDialog from '@/components/view/installer/community/change-plan-dialog';
import { communityApi } from '@/api';
import QuestionTip from '@/components/common/tip/question.vue';
import { FormData } from './all.type';

export default defineComponent({
    emits: ['success', 'close', 'featureSuccess'],
    components: {
        QuestionTip,
        ReadInstructionsDialog,
        ChangePlanDialog
    },
    props: {
        operaType: {
            type: String as PropType<OperateType>,
            required: true
        },
        initForm: {
            type: Object as PropType<FormData>,
            required: true
        }
    },
    setup(props, { emit }) {
        const titles = {
            add: WordList.TabelCommunitiesTitleAdd,
            edit: WordList.TabelCommunitiesTitleModify
        };
        const urls = {
            add: 'v3/web/community/project/add',
            edit: 'v3/web/community/project/edit'
        };
        const rules = ref({
            Location: [
                {
                    required: true,
                    message: WordList.RuleCommonLabelEmpty2.format(WordList.CommunityName),
                    trigger: 'blur'
                }
            ],
            Street: [
                {
                    required: true,
                    message: WordList.PleaseEnterTheFormat.format(WordList.TabelCommunitiesStreet.toLocaleLowerCase()),
                    trigger: 'blur'
                }, {
                    max: 128,
                    message: WordList.StreetTooLong,
                    trigger: 'change'
                }
            ],
            City: [
                {
                    required: true,
                    message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelCommunitiesCity),
                    trigger: 'blur'
                }
            ],
            PostalCode: [
                {
                    validator: postalCodeRule.onlyNumberAndNotEmpty,
                    trigger: 'blur'
                }
            ],
            States: [
                {
                    required: true,
                    message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelCommunitiesState),
                    trigger: 'blur'
                }
            ],
            Country: [
                {
                    required: true,
                    message: WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextCountryRegion),
                    trigger: 'change'
                }
            ]
        });
        const form: Ref<any> = ref(null);
        const formData = reactive({ ...props.initForm });

        const countryOption = addressOption;
        const provinceDisabled = ref(true);
        const cityDisabled = ref(true);
        const provinceOption = ref<{
            label: string;
            value: string;
            city: {
                label: string;
                value: string;
            }[];
        }[]>([]);
        const cityOption = ref<{
            label: string;
            value: string;
        }[]>([]);

        let isFirst = true;
        function changeProvinceOption(val: string) {
            // 清空省份 城市
            if (props.operaType === 'add' || (!isFirst && props.operaType === 'edit')) {
                formData.States = '';
                formData.City = '';
            }

            // 旧数据匹配不上情况
            const country = countryOption.find((item) => item.value === val);
            // eslint-disable-next-line no-nested-ternary
            provinceOption.value = val ? country ? country!.province : [] : [];
            cityOption.value = [];
            if (provinceOption.value.length === 1 && provinceOption.value[0].value === '') {
                // 没有省份的 但有城市的情况
                provinceDisabled.value = true;
                cityDisabled.value = false;
                changeCityOption('', val);
                rules.value.States = [];
            } else if (provinceOption.value.length === 0) {
                // 没有数据 代表国家没有选
                provinceDisabled.value = true;
                cityDisabled.value = true;
            } else {
                provinceDisabled.value = false;
                cityDisabled.value = true;
                rules.value.States = [
                    {
                        required: true,
                        message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelCommunitiesState),
                        trigger: 'blur'
                    }
                ];
            }
        }

        function changeCityOption(val: string, country: string = formData.Country) {
            if (props.operaType === 'add' || (!isFirst && props.operaType === 'edit')) {
                formData.City = '';
            }
            // 旧数据匹配不上情况
            const countryOp = countryOption.find((item) => item.value === country);
            // eslint-disable-next-line no-nested-ternary
            const province = country ? countryOp ? countryOp!.province : [] : [];

            const state = province.find((states) => states.value === val);
            cityOption.value = state ? state!.city : [];
            cityDisabled.value = !(cityOption.value.length > 0);
        }

        if (props.operaType === 'edit') {
            // 如果没有找到国家 把国家数据置空，要求用户重新选择
            // if (!countryOption.find((item) => item.value === formData.Country)) {
            //     formData.Country = '';
            // }
            changeProvinceOption(formData.Country);
            changeCityOption(formData.States);
            isFirst = false;
        }

        const limitBottom = ref(true);
        const limitBottom2 = ref(true);
        const streetBlur = () => {
            if (formData.Street !== '') {
                limitBottom.value = true;
            } else {
                limitBottom.value = false;
            }
            if (formData.States !== '') {
                limitBottom2.value = true;
            } else {
                limitBottom2.value = false;
            }
        };
        const streetBlur2 = () => {
            if (formData.States !== '') {
                limitBottom2.value = true;
            } else {
                limitBottom2.value = false;
            }
        };

        // 控制postCode样式问题
        const postClass = ref('');
        const postcodeBlur = () => {
            if (formData.PostalCode !== '' && formData.PostalCode.replace(/\s*/g, '') === '') {
                postClass.value = 'post-code';
            } else {
                postClass.value = '';
            }
        };
        const submit = () => {
            streetBlur();
            streetBlur2();
            postcodeBlur();
            formData.CustomizeForm = formData.Time1 + formData.Time2;
            if (formData.FeaturePlan === '') {
                noticeBase.messageBox('alert',
                    WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextFeaturePlan),
                    WordList.ProperAllTextFeaturePlan, 'info')(() => false);
                return;
            }
            form.value.validate((valid: boolean) => {
                if (!valid) return false;
                HttpRequest.post(urls[props.operaType], formData, () => {
                    emit('close');
                    window.location.reload();
                });
                return true;
            });
        };
        const showChargeMode = computed(() => community.ChargeMode.value);

        const delCommunity = () => {
            noticeBase.messageBox(
                'confirm',
                WordList.TabelCommunitiesDeleteText,
                WordList.TabelDeleteTitle,
                'warning'
            )(() => {
                communityApi.deleteCommunity({
                    ID: formData.ID
                }, (res: {
                    token: string;
                    msg: string;
                }) => {
                    if (sessionStorage.getItem('token')) {
                        sessionStorage.setItem('token', res.token);
                    } else {
                        localStorage.setItem('token', res.token);
                    }
                    emit('close');
                    window.location.reload();
                });
            });
        };

        // 6.2新增feature配置
        const { featurePlanData, isShowReadInstructionsDialog } = addFeaturePlanData(formData, props.operaType);

        // 6.2编辑featureplan
        const { isShowChangePlanDialog, editFeaturePlanClose } = editFeaturePlanData(emit, formData);

        // tag6.4-smarthome 需求
        const smartHomeDisabled = formData.EnableSmartHome.toString() === '1' && props.operaType === 'edit';
        watch(() => formData.EnableSmartHome, (val) => {
            if (props.operaType === 'add') {
                if (val.toString() === '1') {
                    formData.EnableLandline = '0';
                }
            }
        }, {
            immediate: true
        });

        return {
            timeZone,
            formData,
            submit,
            showChargeMode,
            titles,
            urls,
            streetBlur,
            streetBlur2,
            limitBottom,
            limitBottom2,
            rules,
            form,
            delCommunity,
            featurePlanData,
            isShowReadInstructionsDialog,
            isShowChangePlanDialog,
            editFeaturePlanClose,
            sysConfig: systemConfig.sysConfig,
            smartHomeDisabled,
            isShowCommunityCalls: community.isShowComCalls,
            postClass,
            postcodeBlur,
            countryOption,
            provinceOption,
            cityOption,
            changeProvinceOption,
            changeCityOption,
            provinceDisabled,
            cityDisabled
        };
    }
});
