
import {
    defineComponent
} from 'vue';
import initFeaturePlan from '@/components/view/installer/community/change-plan-dialog/initFeaturePlan';
import OrderDialog from '@/components/view/installer/community/change-plan-dialog/order-dialog.vue';

export default defineComponent({
    emits: ['close'],
    components: {
        OrderDialog
    },
    props: {
        initFeatureId: {
            required: true,
            type: String
        },
        initCommunityId: {
            required: true,
            type: String
        }
    },
    setup(props, { emit }) {
        const {
            featurePlanData, editFeaturePlanData, changeFeaturePlan, featurePlanID,
            differenceData, isShowOrderDialog, orderDialogClose, changeFeaturePlanData
        } = initFeaturePlan(props.initFeatureId, props.initCommunityId, emit);

        return {
            emit,
            featurePlanData,
            editFeaturePlanData,
            changeFeaturePlan,
            featurePlanID,
            isShowOrderDialog,
            differenceData,
            orderDialogClose,
            changeFeaturePlanData
        };
    }
});

