import { ref } from 'vue';
import { featurePlan } from '@/data';
import { FeaturePlanData } from '@/components/view/installer/community/read-instructions-dialog/all.type';
import { FormData } from './all.type';

const addFeaturePlanData = (
    formData: FormData,
    operaType: OperateType
) => {
    const initFormData = ref(formData);
    const featurePlanData = ref<Array<FeaturePlanData>>([]);

    const isShowReadInstructionsDialog = ref(false);

    featurePlan.getDisFeaturePlan({ Type: '0' }, (res: Array<FeaturePlanData>) => {
        if (operaType === 'add') initFormData.value.FeaturePlan = res[0].ID as string;
        featurePlanData.value = res;
    });

    return {
        featurePlanData,
        isShowReadInstructionsDialog
    };
};

const editFeaturePlanData = (emit: any, formData: FormData) => {
    const editFormData = ref(formData);
    const isShowChangePlanDialog = ref(false);

    const editFeaturePlanClose = (val: {featurePlanID: string}) => {
        editFormData.value.FeaturePlan = val.featurePlanID;
        isShowChangePlanDialog.value = false;
        emit('featureSuccess');
    };

    return {
        isShowChangePlanDialog,
        editFeaturePlanClose
    };
};

export default null;
export {
    addFeaturePlanData,
    editFeaturePlanData
};